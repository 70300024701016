<template>
    <bankline/>
    </template>
    
    <script>
    import bankline from '@/components/bankline.vue'
    export default {
        components:{
            bankline
        },
        data() {
            return {
               
    
                }
            }
     
    }
    </script>
    