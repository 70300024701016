<template>
<v-container>
    <v-row>
        <v-col cols="12">
            <v-card class="py-6 px-6">
                <div style="width:80%; margin:0 auto">
                    <div class="convert-head">
                        <h1 class="text-center">Convert Your TXT</h1>
                    </div>
                    <div class="convert-type d-flex align-center">
                        <p class="text-center">
                            <v-icon class="mr-2">mdi-file-star</v-icon>Xero Payroll Bacs
                        </p>
                        <v-icon class="mr-4 ml-4">mdi-arrow-right</v-icon>
                        <p class="text-center">
                            <v-icon class="mr-2">mdi-file-star</v-icon>Bankline
                        </p>

                    </div>
                    <div class="mt-2 mb-2">
                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="paymentDate" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="paymentDate" label="Payment Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="paymentDate" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false">
                                    Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.menu.save(paymentDate)">
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </div>
                    <div v-if="!ConvertDone">
                        <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" @vdropzone-file-added="fileAdded">
                        </vue-dropzone>
                        <v-btn elevation="2" x-large color="#161d3c" dark style="margin:0 auto;display:block; margin-top:2rem;margin-bottom:2rem" @click="Convert" :disabled="loader">
                            <v-icon class="mr-4 text-center">mdi-swap-horizontal-bold</v-icon> Convert
                        </v-btn>
                        <v-progress-circular v-if="loader" :size="50" color="primary" indeterminate style="display: block; margin: 0 auto;"></v-progress-circular>
                    </div>
                    <v-btn elevation="2" x-large color="#161d3c" dark style="margin:0 auto;display:block; margin-top:2rem;margin-bottom:2rem" @click="ResetAll" v-if="ConvertDone">
                        <v-icon class="mr-4 text-center">mdi-swap-horizontal-bold</v-icon> New Convert
                    </v-btn>
                    <v-simple-table v-if="convertedFiles.length > 0">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        File Name
                                    </th>
                                    <th class="text-left">
                                        Download
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in convertedFiles" :key="index">
                                    <td>Converted - {{ item.data.info.name }}</td>
                                    <td>
                                        <v-btn elevation="2" color="#161d3c" dark @click="DownloadFile(index)">Download</v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                </div>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

    
<script>
import {
    saveAs,
    encodeBase64
} from '@progress/kendo-file-saver';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
    components: {
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            loader: false,
            inputFile: null,
            fileData: null,
            FinalResult: [],
            files_form: [],
            paymentDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            convertedFiles: [],
            Const_number: 0,
            ConvertDone: false,
            dropzoneOptions: {
                url: 'https://',
                autoProcessQueue: false,
                autoQueue: false,
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                addRemoveLinks: true,
                acceptedFiles: ['.txt','.BAC'],
                dictDefaultMessage: "<i aria-hidden='true' class='v-icon notranslate mdi mdi-cloud-upload theme--light'></i> Click Or Drag file here to Upload.",
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
                uploadMultiple: true,

            }
        }
    },
    watch: {
        inputFile(val) {
            let reader = new FileReader()
            reader.onload = (event) => {
                var data = {
                    content: event.target.result.split("\n"),
                    date: val.lastModifiedDate
                }
                this.fileData = data
                this.FinalizeConvert()

            }
            reader.readAsText(val)
        },

    },

    methods: {
        ResetAll() {
            this.loader = false;
            this.files_form = []
            this.convertedFiles = []
            this.ConvertDone = false
        },
        fileAdded(file) {
            this.files_form.push(file)
            console.log(this.files_form)
        },
        FileRender(file) {
            let reader = new FileReader()
            var data = {}
            data.info = file
            reader.onload = (event) => {
                data.content = event.target.result.split("\n");
                this.FinalizeConvert(data)
            }
            reader.readAsText(file)
        },
        async Convert() {
            this.loader = true;
            for (var i = 0; i < this.files_form.length; i++) {
                this.FileRender(this.files_form[i])

            }
            this.loader = false;
            this.ConvertDone = true;
        },

        convertDate(inputFormat) {
            function pad(s) {
                return (s < 10) ? '0' + s : s;
            }
            var d = new Date(inputFormat)
            return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('')
        },
        ltrim(str) {
            if (!str) return str;
            return str.replace(/^\s+/g, '');
        },
        rtrim(str) {
            if (!str) return str;
            return str.replace(/\s+$/g, '');
        },
  
        LineAnalysis(line) {
            var NWordIndex = 47 + 1;
            var NSpaceIndex = line.indexOf('            ') + 1;
            var A1 = parseInt(line.substr(NWordIndex - 10, NWordIndex)).toString(); 
            var AccountName = line.substr(NSpaceIndex, line.length - NSpaceIndex);
            var New_sentance = [];
            New_sentance[0] = ',,,';
            New_sentance[1] = '01';
            New_sentance[2] = ',,,,,,,,,';
            New_sentance[3] = this.Const_number;
            New_sentance[4] = ",,,,";
            New_sentance[5] =  A1.substr(0, A1.length - 2) + '.' + A1.substr(A1.length - 2, A1.length + 1);
            New_sentance[6] = ",,";
            New_sentance[7] = this.convertDate(this.paymentDate);
            New_sentance[8] = ",,,,,,";
            New_sentance[9] = line.substr(0 , 6);
            New_sentance[10] = ",,,,,,";
            New_sentance[11] = line.substr(6 , 8);
            New_sentance[12] = ",,";
            New_sentance[13] = this.rtrim(this.ltrim(AccountName));
            New_sentance[14] = ",,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,";
            const withoutCommas = New_sentance.join('');

            console.log(  withoutCommas)
            return withoutCommas;
        },
        async FinalizeConvert(data) {
            var FinalResult = [];

            this.Const_number = data.content[data.content.length - 5].substr(0, 14);
            for (var i = 4; i < data.content.length - 5; i++) {
                FinalResult.push(this.LineAnalysis(data.content[i]))
            }


            // const dataURI = "data:text/plain;base64," + encodeBase64("Hello World!");
            // saveAs(dataURI, "test.txt");
            var result = "";
            for (var i = 0; i < FinalResult.length; i++) {
                result += FinalResult[i] + "\n";
                console.log(FinalResult[i]   )
                console.log(result)
            }

            // var blob = new Blob(result, {
            //     type: "text/plain;charset=utf-8"
            // });
            const dataURI = "data:text/plain;base64," + encodeBase64(result);
            var fileData = {
                dataURI: dataURI,
                data: data
            }
            this.convertedFiles.push(fileData)

        },
        DownloadFile(index) {
            var file = this.convertedFiles[index]
            var name = file.data.info.name.substr(0, file.data.info.name.lastIndexOf('.'));
            saveAs(file.dataURI, "Converted - " + name +'.txt');
        }

    }
}
</script>

    
<style lang="scss">
.convert-head {
    h1 {
        color: #161d3c;
        margin-bottom: 3rem;
        font-size: 3rem;
    }
}

.vue-dropzone {
    border: 4px dashed #161d3c;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;

}

.dz-message {
    color: #161d3c !important;
    font-weight: bold;

    i {
        font-size: 34px;
        color: #161d3c !important;
    }

}

.convert-type {
    align-content: center;
    justify-content: center;
    margin-bottom: 3rem;

    i {
        font-size: 34px;
        color: #161d3c !important;
    }

    p {
        margin: 0;
        font-size: 20px;
    }
}

.theme--light.v-data-table {
    border: 1px solid #000;
    margin: 3rem 0;
    padding: 1rem;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    font-size: 18px;
}
</style>
